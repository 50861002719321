import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import NavBar from "../components/navBar/navBar"
import ReactMarkdown from 'react-markdown'

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

function IndexPage() {
  const { strapiHomepageDescription } = useStaticQuery(graphql`
    {
      strapiHomepageDescription {
        id
        description {
          data {
            description
          }
        }
      }
    }`)

  return (
    <Layout>
      <Seo title="Home" />
      <NavBar />
      <div className={styles.intro}>
        <ReactMarkdown>{strapiHomepageDescription.description.data.description}</ReactMarkdown>
      </div>
    </Layout>
  )
}

export default IndexPage
